import {Injectable} from "@angular/core";
import {StoreCountryEnum} from "@enums/storeCountry.enum";

@Injectable({
    providedIn: 'root'
})
export class CountryService {
    private readonly regexCodePanonceauLuxembourg: RegExp = /^\d{2}LU$/;
    private readonly regexPostalCodeFrench: RegExp = /^\d{5}$/;
    private readonly regexPostalCodeLuxembourg: RegExp = /^L\d{4}$/;
    private readonly regexPhoneFrench: RegExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    private readonly regexPhoneLuxembourger: RegExp = /^(?:\+352\s?)?\d{6,8}$/;
    private readonly regexTvaNumberFrench: RegExp = /^FR\d{11}$/;
    private readonly regexTvaNumberLuxembourg: RegExp = /^LU\d{8}$/;
    private readonly regexRcsNumberLuxembourg: RegExp = /^B\d{4,5}$/;

    public getRegexCodePanonceauLuxembourg(): RegExp {
        return this.regexCodePanonceauLuxembourg;
    }

    public getRegexPostalCodeFrench(): RegExp {
        return this.regexPostalCodeFrench;
    }

    public getRegexPostalCodeLuxembourger(): RegExp {
        return this.regexPostalCodeLuxembourg;
    }

    public getRegexPhoneFrench(): RegExp {
        return this.regexPhoneFrench;
    }

    public getRegexPhoneLuxembourger(): RegExp {
        return this.regexPhoneLuxembourger;
    }

    public getRegexTvaNumberFrench(): RegExp {
        return this.regexTvaNumberFrench;
    }

    public getRegexTvaNumberLuxembourger(): RegExp {
        return this.regexTvaNumberLuxembourg;
    }

    public getRegexRcsNumberLuxembourger(): RegExp {
        return this.regexRcsNumberLuxembourg;
    }

    public getCountryWithCodePanonceau(codePanonceau: string): StoreCountryEnum {
        if (this.isFrenchCodePanonceau(codePanonceau)) return StoreCountryEnum.FRANCE;
        if (this.isLuxembourgerCodePanonceau(codePanonceau)) return StoreCountryEnum.LUXEMBOURG;
    }

    public getTextOfCountryEnum(country: StoreCountryEnum): string {
        switch (country) {
            case StoreCountryEnum.FRANCE:
                return 'France';
            case StoreCountryEnum.LUXEMBOURG:
                return 'Luxembourg';
            default:
                return '';
        }
    }

    public isFrenchCodePanonceau(codePanonceau: string): boolean {
        return !isNaN(+codePanonceau);
    }

    public isLuxembourgerCodePanonceau(codePanonceau: string): boolean {
        return this.regexCodePanonceauLuxembourg.test(codePanonceau);
    }

    public isFrenchPostalCode(postalCode: string): boolean {
        return !isNaN(+postalCode);
    }

    public isLuxembourgPostalCode(postalCode: string): boolean {
        return this.regexPostalCodeLuxembourg.test(postalCode);
    }
}
