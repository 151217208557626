import {InjectionToken} from '@angular/core';

/**
 * Validation default message errors.
 */
export const defaultErrors = {
    required: () => `Ce champ est requis`,
    minlength: ({requiredLength, actualLength}) => `La taille minimale de ce champ est ${requiredLength}`,
    maxlength: ({requiredLength, actualLength}) => `La taille maximale de ce champ est ${requiredLength}`,
    checkedGroup: ({minItems}) => `Ce champ est requis, il faut cocher au moins ${minItems} élément`,
    minDate: ({minDate}) => `La date minimale autorisée est ${minDate}`,
    openRequired: () => `Il faut choisir une heure d'ouverture`,
    closeRequired: () => `Il faut choisir une heure de fermeture`,
    invalidOpenClose: () => `Il faut choisir au une heure de ouverture et fermeture valid`,
    periodNotDisponible: () => `Période indisponible`,
    extension: ({
                    message,
                    refValues
                }) => `Le type de fichier "${refValues[0]}" est non valide, attendu "${refValues[1]}"`,
    fileSize: ({
                   message,
                   refValues
               }) => `La taille du fichier (${Math.round((refValues[0] / 1000000) * 100) / 100}M) n'est pas valide,  elle doit être de ${Math.round((refValues[2] / 1000000) * 100) / 100}M maximum`,
    url: ({message: refValues}) => 'Veuillez saisir un lien valide',
    latitude: ({message: refValues}) => 'Veuillez saisir une latitude valide',
    longitude: ({message: refValues}) => 'Veuillez saisir une longitude valide',
    digit: ({message: refValues}) => 'Ce champ n\'autorise que les chiffres',
    codePanonceauLuxembourger: ({message}) => 'Veuillez saisir un code panonceau luxembourgeois valide',
    postalCodeFrench: ({message: refValues}) => 'Veuillez saisir un code postal français valide',
    postalCodeLuxembourger: ({message: refValues}) => 'Veuillez saisir un code postal luxembourgeois valide',
    phoneFrench: ({message}) => 'Veuillez saisir un numéro de téléphone français valide',
    phoneLuxembourger: ({message}) => 'Veuillez saisir un numéro de téléphone luxembourgeois valide',
    idBaseAdhérentAcdelec: ({message}) => 'Veuillez saisir un id base adhérent Acdelec au format XXXX-XXX-X',
    email: ({message}) => 'Veuillez saisir une adresse mail valide',
    tvaFrench: ({message: refValues}) => 'Veuillez saisir un numéro tva intra communautaire français valide',
    tvaLuxembourger: ({message: refValues}) => 'Veuillez saisir un numéro tva intra communautaire luxembourgeois valide',
    rcsLuxembourger: ({message: refValues}) => 'Veuillez saisir un numéro rcs luxembourgeois valide',
    establishmentAuthorizations: ({message: refValues}) => 'Format incorrect : 8 chiffres, espace, /, espace, 1 ou 3 chiffres',
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultErrors
});


