import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {PointVenteRepository} from "@galec/repositories/pointVenteEtHoraires.gql";
import {ExcelService} from "@services/excel.service";
import {PointVente} from "@models/pointVente.model";
import {CountryService} from "@services/country.service";
import {HoraireService} from "@services/horaire.service";
import {DayService} from "@services/day.service";
import {UserAuthenticationService} from "@galec/services";
import {OuverturesFermeturesExceptionnellesRepository} from "@galec/repositories";
import {OuverturesFermeturesExceptionnelles} from "@galec/database/models/frontend";
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {ActualiteRepository} from "@galec/repositories/actualite.gql";
import {ActualiteExport} from "@models/actualiteExport.model";

@Component({
    selector: 'export-data',
    templateUrl: './export-data.component.html',
    styleUrls: ['./export-data.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ExportDataComponent implements OnInit {

    formGroup: FormGroup;
    yearControl: FormControl;

    isCentral: boolean;
    codePanonceauCentrale: string;

    noExceptionalOpenClose: boolean;
    noActuality: boolean;

    years: number[] = [];

    constructor(
        private pointVenteRepository: PointVenteRepository,
        private actualiteRepository: ActualiteRepository,
        private ouverturesFermeturesExceptionnellesRepository: OuverturesFermeturesExceptionnellesRepository,
        private excelService: ExcelService,
        private countryService: CountryService,
        private horaireService: HoraireService,
        private dayService: DayService,
        private userAuthenticationService: UserAuthenticationService,
        private router: Router,
        private fb: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.initFormGroup();
        this.getContextData();
        this.getYears();
    }

    private getContextData(): void {
        const urlSplit = this.router.url.split('/');
        const role = urlSplit[3];
        switch (role) {
            case 'national':
                this.isCentral = false;
                break;
            case 'centrale':
                const codePanonceauCentrale = urlSplit[5];
                this.isCentral = true;
                this.codePanonceauCentrale = codePanonceauCentrale.toUpperCase();
                break;
        }
    }

    private getYears(): void {
        this.ouverturesFermeturesExceptionnellesRepository.getFirstAndLastDate().subscribe(
            value => {
                const firstDate = value.data.ouvertures_fermetures_exceptionnelles_aggregate.aggregate.min.date;
                const lastDate = value.data.ouvertures_fermetures_exceptionnelles_aggregate.aggregate.max.date;
                const firstYear = +firstDate.split('-')[0];
                const lastYear = +lastDate.split('-')[0];
                for (let year = firstYear; year <= lastYear; year++) {
                    this.years.push(year);
                }
                this.years.sort((a, b) => {
                    return a - b;
                });
                this.yearControl.setValue(this.getDefaultYear());
            }, error => console.error(error));
    }

    private initFormGroup(): void {
        this.yearControl = new FormControl();
        this.formGroup = this.fb.group({
            year: this.yearControl,
        });
    }

    private getDefaultYear(): number {
        const now = new Date();
        if (!this.years.includes(now.getFullYear())) return this.years[this.years.length - 1];
        return now.getFullYear();
    }

    public getButtonClass(): string {
        let buttonClass = 'c-btn';
        if (this.isCentral) buttonClass += ' c-btn--blue';
        return buttonClass;
    }

    public downloadDataStore(): void {
        const name = `Données points de ventes actifs avec horaires ${(!this.isCentral) ? 'National' : 'Centrale'}`;
        const response = (!this.isCentral) ? this.pointVenteRepository.getAllPointVenteNationalIsActif() : this.pointVenteRepository.getAllPointVenteCentraleIsActif(this.codePanonceauCentrale);
        response.subscribe(value => {
                const pointsVente: PointVente[] = value.data.pointvente;
                const data = this.excelService.transformDataToExcelDataStore(pointsVente);
                this.excelService.exportAsExcelFile(data, name);
            }, error => console.error(error));
    }

    public downloadDataOuvertureFermetureExceptionnelles(): void {
        const year = this.yearControl.value;
        const name = `Ouvertures et fermetures exceptionnelles ${(!this.isCentral) ? 'National' : 'Centrale'} ${year}`;
        const response = (!this.isCentral) ? this.ouverturesFermeturesExceptionnellesRepository.getAllOuvertureFermetureExceptionnellesNationalForYear(year) : this.ouverturesFermeturesExceptionnellesRepository.getAllOuvertureFermetureExceptionnellesCentraleForYear(this.codePanonceauCentrale, year);
        response.subscribe(value => {
            if (value !== null && value.data !== null && value.data.ouvertures_fermetures_exceptionnelles.length !== 0){
                this.noExceptionalOpenClose = false;
                const ouverturesFermeturesExceptionnelles: OuverturesFermeturesExceptionnelles[] = value.data.ouvertures_fermetures_exceptionnelles;
                const data = this.excelService.transformDataToExcelOuvertureFermetureExceptionnelles(ouverturesFermeturesExceptionnelles);
                this.excelService.exportAsExcelFile(data, name);
            } else {
                this.noExceptionalOpenClose = true;
            }
        }, error => console.error(error));
    }

    public downloadDataActualite(): void {
        const name = `Actualites ${(!this.isCentral) ? 'National' : 'Centrale'}`;
        const response = (!this.isCentral) ? this.actualiteRepository.getAllActualitesNational() : this.actualiteRepository.getAllActualitesCentrale(this.codePanonceauCentrale);
        response.subscribe(value => {
            console.log(value);
            if (value !== null && value.data !== null && value.data.actualite.length !== 0){
                this.noActuality = false;
                const actualites: ActualiteExport[] = value.data.actualite;
                const data = this.excelService.transformDataToActualite(actualites);
                this.excelService.exportAsExcelFile(data, name);
            } else {
                this.noActuality = true;
            }
        }, error => console.error(error));
    }
}
