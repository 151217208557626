<section class="export-data-section">
    <h1>Export données magasins</h1>
    <div class="groupe-btn">
        <button (click)="downloadDataStore()" [class]="getButtonClass()">Export données points de ventes actifs avec horaires</button>
        <div class="container-button">
            <button (click)="downloadDataActualite()" [class]="getButtonClass()">Export données actualités</button>
            <p *ngIf="noActuality"> Pas d'actualité à exporter</p>
        </div>
        <div class="container-button" [formGroup]="formGroup">
            <select formControlName="year" class="select-pdv form-control w-50 mb-5">
                <option *ngFor="let year of years" [value]="year">
                    {{ year }}
                </option>
            </select>
            <button (click)="downloadDataOuvertureFermetureExceptionnelles()" [class]="getButtonClass()">Export ouvertures et fermetures exceptionnelles</button>
            <p *ngIf="noExceptionalOpenClose"> Pas d'ouverture/fermeture exceptionnelle à exporter sur cette période</p>
        </div>
    </div>
</section>