// Database interface data model
import {IActualite} from '../interfaces';
import {BaseModel} from './model';
import {PointventeActualite} from "@models/pointventeActualite.model";
import * as moment from "moment";


/**
 * Application class actualite.
 */
export class Actualite extends BaseModel implements IActualite {
    id_actualite: number;
    titre: string;
    image: string;
    texte: string;
    date_debut: string;
    date_fin: string;
    actu_centrale: boolean;
    page_detail: boolean;
    draft: boolean;
    model: boolean;
    type: string;
    lien: string;
    created_at: string;
    modelActualite: string;
    typeActualite: string;
    ordre: number;
    heure_fin: string;
    heure_debut: string;
    pointvente_actualites: { data: PointventeActualite[] };
    timeStamp: number;

    constructor(actualite: IActualite | any) {
        super();
        // Initialize all attributes.
        this.init(this, actualite);
        this.setPointventeActualite(actualite);
        this.splitDateAndTime();
        this.timeStamp = Date.now();
    }


    /**
     * Get unique id.
     * @return string       Id unique of object.
     */
    public get id(): string {
        return this.id_actualite.toString();
    }

    /**
     * Prepare data to be persisted in graphql database.
     * @return object       Object of necessary value to be persisted.
     */
    public persist(): Actualite {
        this.mergeDateAndTime();
        return {
            actu_centrale: this.actu_centrale,
            date_debut: this.date_debut,
            date_fin: this.date_fin,
            id_actualite: this.id_actualite,
            image: this.image,
            page_detail: this.page_detail,
            texte: this.texte,
            titre: this.titre,
            pointvente_actualites: this.pointvente_actualites,
            draft: this.draft,
            model: this.model,
            type: this.type,
            lien: this.lien,
        } as Actualite;
    }

    /**
     * Describe object necessary attribute to string.
     * @return string   Description object.
     */
    public toString(): string {
        return null;
    }

    private splitDateAndTime() {
        if (this.date_debut && moment(this.date_debut).isValid() && !this.heure_debut) {
            this.heure_debut = moment(this.date_debut).format('HH:mm');
            this.date_debut = moment(this.date_debut).format('YYYY-MM-DD');
        }
        if (this.date_fin && moment(this.date_fin).isValid() && !this.heure_fin) {
            this.heure_fin = moment(this.date_fin).format('HH:mm');
            this.date_fin = moment(this.date_fin).format('YYYY-MM-DD');
        }
    }

    private mergeDateAndTime() {
        if (moment(this.date_debut, 'DD/MM/YYYY').isValid()) {
            this.date_debut = moment(`${this.date_debut} ${this.heure_debut}`, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
        }
        if (moment(this.date_fin, 'DD/MM/YYYY').isValid()) {
            this.date_fin = moment(`${this.date_fin} ${this.heure_fin}`, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
        }
    }

    private setPointventeActualite(actualite: IActualite | any) {
        if (actualite?.pointvente_actualites) {
            const act = [];
            actualite?.pointvente_actualites.forEach(val => {
                act.push(new PointventeActualite(val));
            });
            this.pointvente_actualites = {
                data: act
            };
        }
    }

    public get Image() {
        return `${this.image}&time=${this.timeStamp}`;
    }
}
